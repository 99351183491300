import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import useApiAxios from "../../api/useApiAxios";
import * as Yup from "yup";
import Select from "react-select";
import DragsAndDrop from "../../image/DragsAndDrop";

const EditMarket = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const {
    updateMarketApi,
    updateMarketImage,
    getAllCategoryApi,
    getAllSubCategoryApi,
  } = useApiAxios();
  const navigate = useNavigate();
  const IMAGEENDPOINT = process.env.REACT_APP_IMG_URL;

  const user = location.state?.user;
  const [imagePreview, setImagePreview] = useState(null);
  const [mapImagePreview, setMapImagePreview] = useState(null);

  // Refs for focusing on fields
  const nameRef = useRef(null);
  const totalstallRef = useRef(null);
  const imageRef = useRef(null);
  const mapImageRef = useRef(null);
  const categoryRef = useRef(null);
  const subCategoryRef = useRef(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .matches(/^\S/, "Name cannot start with a space")
      .required("Name is required"),
    totalstall: Yup.number()
      .positive("Stall must be a positive number")
      .integer("Stall must be an integer")
      .typeError("Stall must be a valid number"),
    category: Yup.array()
      .min(1, "At least one category is required")
      .required("Category is required"),
    subCategory: Yup.array()
      .min(1, "At least one subcategory is required")
      .required("Subcategory is required"),
    image: Yup.mixed()
      .test(
        "fileSize",
        "File size is too large, Size should be less than 4 MB",
        (value) => {
          if (value && value.size) {
            return value.size <= 1024 * 1024 * 4; // 4MB
          }
          return true;
        }
      )
      .test("fileFormat", "Unsupported file format", (value) => {
        if (value && value.type) {
          return ["image/jpg", "image/jpeg", "image/png"].includes(value.type);
        }
        return true;
      }),
    mapImage: Yup.mixed()
      .test(
        "fileSize",
        "File size is too large, Size should be less than 4 MB",
        (value) => {
          if (value && value.size) {
            return value.size <= 1024 * 1024 * 4; // 4MB
          }
          return true;
        }
      )
      .test("fileFormat", "Unsupported file format", (value) => {
        if (value && value.type) {
          return ["image/jpg", "image/jpeg", "image/png"].includes(value.type);
        }
        return true;
      }),
  });

  const initialValues = {
    name: user ? user.name : "",
    totalstall: user ? user.totalstall : "",
    category: user ? user.category.map((cat) => cat._id) : [],
    subCategory: user ? user.subCategory.map((subCat) => subCat._id) : [],
    image: user ? user.image : "",
    mapImage: user ? user.mapImage : "",
  };

  useEffect(() => {
    getAllCategoryApi()
      .then((response) => {
        const options = response.data.data.map((category) => ({
          value: category._id,
          label: category.name,
        }));
        setCategory(options);
        const selectedCategories = user.category.map((cat) => ({
          value: cat._id,
          label: cat.name,
        }));
        setSelectedCategory(selectedCategories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, [user.category]);

  useEffect(() => {
    getAllSubCategoryApi()
      .then((response) => {
        const options = response.data.data.map((subCategory) => ({
          value: subCategory._id,
          label: subCategory.name,
        }));
        setSubCategory(options);
        const selectedSubCategories = user.subCategory.map((subCat) => ({
          value: subCat._id,
          label: subCat.name,
        }));
        setSelectedSubCategory(selectedSubCategories);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  }, [user.subCategory]);

  useEffect(() => {
    if (user && user.image) {
      setImagePreview(`${user.image}`);
    }
    if (user && user.mapImage) {
      setMapImagePreview(`${user.mapImage}`);
    }
  }, [user, IMAGEENDPOINT]);

  const onSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    try {
      await updateMarketApi(user._id, {
        ...values,
        category: selectedCategory.map((cat) => cat.value),
        subCategory: selectedSubCategory.map((subCat) => subCat.value),
      });

      const formData = new FormData();
      if (values.image) {
        formData.append("image", values.image);
      }
      if (values.mapImage) {
        formData.append("mapImage", values.mapImage);
      }

      await updateMarketImage(user._id, formData);
      navigate("/viewmarket");
    } catch (error) {
      console.error("Error updating market:", error);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const handleImageChange = (file) => {
    // const file = event.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      formik.setFieldValue("image", file);
    }
  };

  const handleMapImageChange = (file) => {
    if (file) {
      setMapImagePreview(URL.createObjectURL(file));
      formik.setFieldValue("mapImage", file);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.errors.name && formik.touched.name) {
      nameRef.current.focus();
    } else if (formik.errors.totalstall && formik.touched.totalstall) {
      totalstallRef.current.focus();
    } else if (formik.errors.image && formik.touched.image) {
      imageRef.current.focus();
    } else if (formik.errors.mapImage && formik.touched.mapImage) {
      mapImageRef.current.focus();
    } else if (formik.errors.category && formik.touched.category) {
      categoryRef.current.focus();
    } else if (formik.errors.subCategory && formik.touched.subCategory) {
      subCategoryRef.current.focus();
    }
  }, [formik.errors, formik.touched]);

  return (
    <>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Edit Market</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/viewmarket">Market</Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Edit Market</a>
                </li>
              </ul>
            </div>
            <div className="ml-md-auto py-2 py-md-0">
              <div className="row">
                <div className="col-6">
                  <input
                    type="text"
                    name="dates"
                    className="form-control pull-right"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Edit Market</h4>
              </div>
              <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Market Name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                          ref={nameRef}
                        />
                        {formik.errors.name && formik.touched.name && (
                          <small className="form-text text-danger">
                            {formik.errors.name}
                          </small>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="totalstall">Total Stalls</label>
                        <input
                          type="number"
                          id="totalstall"
                          name="totalstall"
                          className="form-control"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.totalstall}
                          ref={totalstallRef}
                        />
                        {formik.errors.totalstall &&
                          formik.touched.totalstall && (
                            <small className="form-text text-danger">
                              {formik.errors.totalstall}
                            </small>
                          )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="subCategory">Category</label>
                        <Select
                          id="subCategory"
                          name="subCategory"
                          isMulti
                          options={subCategory}
                          value={selectedSubCategory}
                          onChange={(selectedOptions) => {
                            setSelectedSubCategory(selectedOptions);
                            formik.setFieldValue(
                              "subCategory",
                              selectedOptions
                            );
                          }}
                          onBlur={formik.handleBlur}
                          ref={subCategoryRef}
                        />
                        {formik.errors.subCategory &&
                          formik.touched.subCategory && (
                            <small className="form-text text-danger">
                              {formik.errors.subCategory}
                            </small>
                          )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="category">Sub Category</label>
                        <Select
                          id="category"
                          name="category"
                          isMulti
                          options={category}
                          value={selectedCategory}
                          onChange={(selectedOptions) => {
                            setSelectedCategory(selectedOptions);
                            formik.setFieldValue("category", selectedOptions);
                          }}
                          onBlur={formik.handleBlur}
                          ref={categoryRef}
                        />
                        {formik.errors.category && formik.touched.category && (
                          <small className="form-text text-danger">
                            {formik.errors.category}
                          </small>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="image">Market Image</label>
                        {/* <input
                          type="file"
                          id="image"
                          name="image"
                          className="form-control"
                          onChange={handleImageChange}
                          ref={imageRef}
                        />
                        {imagePreview && (
                          <img
                            src={imagePreview}
                            alt="Market"
                            style={{ maxWidth: "300px", maxHeight: "300px" }}
                          />
                        )} */}
                        <DragsAndDrop
                          heading="Upload Image"
                          inputName="Image"
                          imgKey={imagePreview}
                          aspect={2 / 1}
                          uploadFile={(x) => {
                            handleImageChange(x);
                          }}
                        />
                        {formik.errors.image && formik.touched.image && (
                          <small className="form-text text-danger">
                            {formik.errors.image}
                          </small>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="mapImage">Market Map Image</label>
                        {/* <input
                          type="file"
                          id="mapImage"
                          name="mapImage"
                          className="form-control"
                          onChange={handleMapImageChange}
                          ref={mapImageRef}
                        />
                        {mapImagePreview && (
                          <img
                            src={mapImagePreview}
                            alt="Map"
                            style={{ maxWidth: "300px", maxHeight: "300px" }}

                          />
                        )} */}
                        <DragsAndDrop
                          heading="Upload Image"
                          inputName="Image"
                          imgKey={mapImagePreview}
                          aspect={2 / 1}
                          uploadFile={(x) => {
                            handleMapImageChange(x);
                          }}
                        />
                        {formik.errors.mapImage && formik.touched.mapImage && (
                          <small className="form-text text-danger">
                            {formik.errors.mapImage}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* Additional fields can be added here */}
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-primary m-2"
                    >
                      {loading ? "Updating..." : "Update"}
                    </button>
                    <Link to="/viewmarket">
                      <button type="button" className="btn btn-danger">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMarket;
