import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Context } from "./context/Context";
import useApiAxios from "./api/useApiAxios";
import Login from "./login/Login";
import Loader from "./components/loader/Loader";
import SnackbarComponent from "./components/snackbarComponent/SnackbarComponent";
import Backend from "./components/layout/Backend";
import DashBoard from "./components/DashBoard";
import CreateMarket from "./components/market/CreateMarket";
import EditMarket from "./components/market/EditMarket";
import ViewMarket from "./components/market/ViewMarket";
import ForgetPassword from "./components/forgetPassword/ForgetPassword";
import VendorList from "./components/vendor/VendorList";
import EditVendor from "./components/vendor/EditVendor";
import ViewVendor from "./components/vendor/ViewVendor";
import InvoiceSummary from "./components/invoiceSummary/InvoiceSummary.jsx";
import VendorPaymentList from "./components/vendorpayment/VendorPaymentList.jsx";
import VendorSuccess from "./components/payment/VendorSuccess.jsx";
import MarketDate from "./components/marketdate/MarketDate";
import AddSubAdminLandingPageDetail from "./components/subadminlandingpagedetail/addSubAdminLandingPageDetail";
import ViewDate from "./components/marketdate/ViewDate";
import EditSubAdminLandingPageDetails from "./components/subadminlandingpagedetail/editSubAdminLandingPageDetails";
import SuccessPage from "./components/payment/SuccessPage";
import Category from "./components/category/Category";
import EditCategory from "./components/category/EditCategory";
import Profile from "./components/profile/Profile";
import VendorProfile from "./components/profile/VendorProfile";
import VendorDate from "./components/Date/vendorDate";
import Tutorial from "./components/tutorial/Tutorial";
import PaymentHistory from "./components/paymenthistory/PaymentHistory";
import MapMapping from "./components/mapMapping/MapMapping";
import PendingStatus from "./components/pending/pendingPage.jsx";
import EmailLogs from "./components/emailLogs/EmailLogs.jsx";


import ViewDateDetail from "./components/Date/viewDateDetail.jsx"
import AppliedVendorList from "./components/appliedvendors/AppliedVendorList.jsx";
import AttendanceList from "./components/attendancelist/AttendanceList.jsx";
import InVoiceList from "./components/invoicelist/InVoiceList.jsx";
import Dashboard from "./components/DashBoard";
import InvoiceHistory from "./components/invoicelist/InvoiceHistory.jsx";
import SubCategory from "./components/subcategory/subCategory.jsx";
import EditSubCategory from "./components/subcategory/editSubCategory.jsx";
import ViewVendorDetail from "./components/vendor/ViewVendorDetail.jsx";
import MapCanvas from "./components/mapMapping/MapCanvas.jsx";
import ApproveVendorChanges from "./components/vendor/ApproveVendorChanges.jsx";
import VendorChangeDetails from "./components/vendor/VendorChangeDetails.jsx";


const App = () => {
  const { isLoading, isAuthorized, userData } = useContext(Context);
  const [firstLoading, setFirstLoading] = useState(true);
  const { homeApi } = useApiAxios();
  const [dashboardData, setDashboardData] = useState([]);


  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    setFirstLoading(true);
    const response = await homeApi();
    setDashboardData(response);

    setFirstLoading(false);
  };

  if (firstLoading) {
    return <div>Loading...</div>
  }



  if (!isAuthorized) {
    return (
      <>
        <SnackbarComponent />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/invoice/public/:vendorInvoiceId" element={<InvoiceSummary />} />
          <Route path="/subscription/success" element={<SuccessPage />} />
          <Route path="/vendor/subscription/success" element={<VendorSuccess />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
        </Routes>
      </>
    );
  }

  if (userData.role === "subAdmin" && !userData.tutorialWatch) {
    return (
      <>
        <SnackbarComponent />
        <Routes>

          <Route path="/invoice/public" element={<InvoiceSummary />} />
        </Routes>

        <Tutorial />
      </>
    );
  }

  if (userData.role === "vendor" && userData.vendor.approvalStatus === "pending") {
    return <PendingStatus />;
  }

  return (
    <>
      <Loader open={isLoading} />
      <SnackbarComponent />
      <Routes>
        <Route path="/subscription/success" element={<SuccessPage />} />
        <Route path="/vendor/subscription/success" element={<VendorSuccess />} />
        <Route path="/invoice/public/:vendorInvoiceId" element={<InvoiceSummary />} />

        <Route path="/" element={<Backend />}>
          {userData.role === "vendor" ? (
            <Route path="/" element={<VendorProfile />} />
          ) : (
            <Route path="/" element={<Dashboard />} />
          )}
          <Route path="/profile" element={<Profile />} />
          <Route path="/vendordate" element={<VendorDate />} />
          {/* <Route path="/" element={<VendorList />} /> */}
          <Route path="/createmarket" element={<CreateMarket />} />
          <Route path="/viewmarket" element={<ViewMarket />} />



          <Route path="/subcategory" element={<Category />} />
          <Route path="/category" element={<SubCategory />} />

          <Route path="/editmarket/:id" element={<EditMarket />} />
          <Route path="/vendorlist" element={<VendorList />} />
         

          <Route path="/approvevendorchanges" element={<ApproveVendorChanges />} />
          <Route path="/approvevendorchangesdetails" element={<VendorChangeDetails />} />
          
          <Route path="/viewvendor/:id" element={<ViewVendor />} />
          <Route path="/editvendor/:id" element={<EditVendor />} />
          <Route path="/marketdate" element={<MarketDate />} />
          <Route path="/paymenthistory" element={<PaymentHistory />} />
          <Route path="/map" element={<MapMapping />} />
          <Route path="/emaillogs" element={<EmailLogs />} />
          <Route path="/appliedvendors" element={<AppliedVendorList />} />
          <Route path="/attendancelist" element={<AttendanceList />} />
          <Route path="/invoicelist" element={<InVoiceList />} />
          <Route path="/invoicehistory" element={<InvoiceHistory />} />
          <Route path="/vendor/paymenthistory" element={<VendorPaymentList />} />
          <Route path="/vendor-details" element={<ViewVendorDetail />} />
          <Route path="/map/canvas/:id" element={<MapCanvas />} />
          <Route
            path="/addLandingPageDetail/:id"
            element={<AddSubAdminLandingPageDetail />}
          />
          <Route
            path="/editLandingPageDetails/:marketId"
            element={<EditSubAdminLandingPageDetails />}
          />
          <Route path="/viewDate/:marketId" element={<ViewDate />} />
          <Route path="/editcategory/:id" element={<EditCategory />} />
          <Route path="/editsubcategory/:id" element={<EditSubCategory />} />

          <Route path="/viewcategory" element={<Category />} />
          <Route path="/tutorial" element={<Tutorial />} />
          <Route path="/viewdatedetail/:id" element={<ViewDateDetail />} />




        </Route>
      </Routes>
    </>
  );
};

export default App;
