import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import SubAdminSidebarData from "./SubAdminSidebar.json";
import VendorSidebarData from "./VendorSideBar.json";
import "./sidebar.css";

const SideBar = ({ isCollapsed }) => {
  const { userData } = useContext(Context);
  const sidebarData = userData.role === "vendor" ? VendorSidebarData : SubAdminSidebarData;
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const toggleSubMenu = (index) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <div className="user">
          <div className="avatar-sm float-left mr-2">
            <img src="/assets/img/profile.jpg" alt="Profile" className="avatar-img rounded-circle" />
          </div>
          {!isCollapsed && (
            <div className="info">
              <a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
                <span>
                  {userData.name || userData.vendor?.businessName}
                  <span className="user-level">{userData.email}</span>
                </span>
              </a>
            </div>
          )}
        </div>
        <ul className="nav nav-primary">
          {sidebarData.menuItems.map((menuItem, index) => (
            <li className="nav-item" key={menuItem.id || index}>
              {menuItem.subMenu ? (
                <>
                  <a onClick={() => toggleSubMenu(index)} style={{ cursor: 'pointer' }}>
                    <i className={menuItem.icon} />
                    {!isCollapsed && <p>{menuItem.title}</p>}
                    <span className="caret" />
                  </a>
                  <div className={`collapse ${openSubMenu === index ? 'show' : ''}`}>
                    <ul className="nav nav-collapse">
                      {menuItem.subMenu.map((subItem, subIndex) => (
                        <li key={subItem.id || subIndex}>
                          <Link to={subItem.link}>
                            <span className="sub-item">{subItem.title}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                <Link to={menuItem.link}>
                  <i className={menuItem.icon} />
                  {!isCollapsed && <p>{menuItem.title}</p>}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
