import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate"; // Import ReactPaginate
import { format } from "date-fns";

const AttendanceList = () => {
  const {
    vendorAttendanceList,
    getAllMarket,
    vendorAttendancePresent,
    vendorAttendanceAbsent,
  } = useApiAxios();
  const { userData } = useContext(Context);
  const [marketList, setMarketList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMarketId, setSelectedMarketId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [attendencePerPage, setAttendencePerPage] = useState(10);
  const attendencePerPageOptions = [5, 10, 20, 50];

  useEffect(() => {
    const fetchMarketList = async () => {
      try {
        const response = await getAllMarket();
        setMarketList(response.data.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchMarketList();
  }, []);

  useEffect(() => {
    if (selectedMarketId) {
      const market = marketList.find((m) => m._id === selectedMarketId);
      console.log("Selected Market:", market); // Debugging statement

      if (market && market.marketDate) {
        const dates = market.marketDate.map((dateObj) => ({
          id: dateObj._id,
          date: new Date(dateObj.date).toLocaleDateString("en-US"), // Format date as needed
        }));
        console.log("Dates:", dates); // Debugging statement
        setDateList(dates);
      }
    }
  }, [selectedMarketId, marketList]);

  const fetchAttendanceData = async () => {
    try {
      const response = await vendorAttendanceList(
        selectedMarketId,
        selectedDate,
        "approved"
      );
      setAttendanceData(response.data.data);
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    if (selectedMarketId && selectedDate) {
      fetchAttendanceData();
    }
  }, [selectedMarketId, selectedDate]);

  const handleMarketChange = (e) => {
    setSelectedMarketId(e.target.value);
    setSelectedDate(null); // Clear the selected date when market changes
    setDateList([]); // Clear previous dates
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handlePresentClick = async (vendorId) => {
    try {
      const response = await vendorAttendancePresent(vendorId);
      console.log(`Present API Response for Vendor ID ${vendorId}:`, response);
      // Optionally update the UI to reflect the change
      fetchAttendanceData();
    } catch (err) {
      console.error(
        `Error marking vendor ID ${vendorId} as present:`,
        err.message
      );
    }
  };

  const handleAbsentClick = async (vendorId) => {
    try {
      const response = await vendorAttendanceAbsent(vendorId);
      console.log(`Absent API Response for Vendor ID ${vendorId}:`, response);
      fetchAttendanceData();
      // Optionally update the UI to reflect the change
    } catch (err) {
      console.error(
        `Error marking vendor ID ${vendorId} as absent:`,
        err.message
      );
    }
  };
  function camelToFirstLetterCapital(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  function camelToStartCase(str) {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert spaces before capital letters
      .replace(/^./, (char) => char.toUpperCase()) // Capitalize the first letter of the string
      .replace(/ (.)/g, (char) => char.toUpperCase()); // Capitalize the first letter after each space
  }

  const indexOfLastVendor = (currentPage + 1) * attendencePerPage;
  const indexOfFirstVendor = indexOfLastVendor - attendencePerPage;
  const currentVendors = attendanceData.slice(
    indexOfFirstVendor,
    indexOfLastVendor
  );

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleVendorsPerPageChange = (event) => {
    setAttendencePerPage(Number(event.target.value));
    setCurrentPage(0); // Reset to the first page when changing the number of vendors per page
  };

  return (
    <div>
      <style>{`
                .market-select, .date-select {
                    padding: 10px;
                    margin: 5px;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    font-size: 16px;
                }

                .table {
                    width: 100%;
                    margin: 20px 0;
                    border-collapse: collapse;
                }

                .table th, .table td {
                    padding: 10px;
                    text-align: left;
                    border: 1px solid #ddd;
                }

                .table th {
                    background-color: #f2f2f2;
                }

                .action-buttons button {
                    margin-right: 5px;
                    padding: 5px 10px;
                    border: none;
                    border-radius: 3px;
                    cursor: pointer;
                }

                .button-present {
                    background-color: #28a745;
                    color: white;
                }

                .button-absent {
                    background-color: #dc3545;
                    color: white;
                }

                .message-box {
                    padding: 20px;
                    margin: 20px 0;
                    border: 1px solid #ddd;
                    background-color: #f9f9f9;
                    text-align: center;
                    font-size: 16px;
                    border-radius: 5px;
                }
            `}</style>

      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Attendance List</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Attendance List </a>
                </li>
              </ul>
            </div>
            <div className="ml-md-auto py-2 py-md-0">
              <select
                onChange={handleMarketChange}
                value={selectedMarketId || ""}
                className="market-select"
              >
                <option value="" disabled>
                  Select a market
                </option>
                {marketList.map((market) => (
                  <option key={market._id} value={market._id}>
                    {market?.name}
                  </option>
                ))}
              </select>

              {selectedMarketId && (
                <div className="ml-md-auto py-2 py-md-0">
                  <select
                    onChange={handleDateChange}
                    value={selectedDate || ""}
                    className="date-select"
                  >
                    <option value="" disabled>
                      Select a date
                    </option>
                    {dateList.map((date) => (
                      <option key={date.id} value={date.id}>
                       {format(new Date(date.date), "dd/MM/yyyy")}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {attendanceData.length > 0 ? (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Vendor Email</th>
                <th>Business Name</th>
                <th>Contact Person</th>
                <th>Contact Number</th>
                <th>Category</th>
                <th>Sub-Category</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {attendanceData.map((entry) => (
                <tr key={entry._id}>
                  <td>{entry.vendor.email}</td>
                  <td>{entry.vendor.businessName}</td>
                  <td>{entry.vendor.contactPerson}</td>
                  <td>{entry.vendor.contactNumber}</td>
                  <td>{entry.vendor.category[0]?.name}</td>
                  <td>{entry.vendor.subCategory[0]?.name}</td>
                  <td>
                    {entry.status === "InvoiceSend"
                      ? "Payment Not Done"
                      : camelToStartCase(entry.status)}
                  </td>
                  <td className="action-buttons">
                    <button
                      className="button-present"
                      onClick={() => handlePresentClick(entry._id)}
                      disabled={!(entry.status === "paymentDone")}
                    >
                      Present
                    </button>
                    <button
                      className="button-absent"
                      onClick={() => handleAbsentClick(entry._id)}
                      disabled={!(entry.status === "paymentDone")}
                    >
                      Absent
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="d-flex justify-content-between align-items-center pagination-container">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={Math.ceil(attendanceData.length / attendencePerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
            <div className="form-group d-flex align-items-center items-per-page">
              <label htmlFor="vendorsPerPage">Vendors per page: </label>

              <select
                value={attendencePerPage}
                onChange={handleVendorsPerPageChange}
                className="form-control m-2"
                style={{ width: "auto", display: "inline-block" }}
              >
                {attendencePerPageOptions.map((option) => (
                  <option key={option} value={option}>
                    Show {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      ) : (
        <div className="message-box">
          {selectedMarketId && selectedDate
            ? "No attendance data available or vendors haven't applied for the selected date."
            : "Please select a market and date to view attendance data."}
        </div>
      )}
    </div>
  );
};

export default AttendanceList;
