import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { fabric } from "fabric";
import VendorMapModal from "./vendorMapModal";

const MapCanvas = () => {
  const { id } = useParams();
  const [date, setDate] = useState();
  const [data, setData] = useState([]);
  const { getVendorListForMapApi } = useApiAxios();
  const canvasRef = useRef(null);
  const fabricCanvasRef = useRef();
  const [availableCircles, setAvailableCircles] = useState([]);
  const [vendorIndex, setVendorIndex] = useState(1);
  const IMAGEENDPOINT = process.env.REACT_APP_IMG_URL;
  const { postMapImage } = useApiAxios();
  const mapData = date?.mapData;
  const navigate = useNavigate();

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getVendorListForMapApi(id);
      if (!res.error) {
        setDate(res.data.date);
        setData(res.data.data);
        setSelectedVendor(res)
        console.warn("OOOOOOOOOOOOOOOOO",res)
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (date?.market?.mapImage) {
      initializeCanvas(date?.market?.mapImage);
    }

    return () => {
      disposeCanvas();
    };
  }, [date]);

  const loadMapImage = (mapImage) => {
    const fullImageUrl = IMAGEENDPOINT + "/" + mapImage;
    const fabricCanvas = fabricCanvasRef.current;

    if (!fabricCanvas) {
      return;
    }

    const img = new Image();
    img.src = fullImageUrl;
    img.crossOrigin = "anonymous";

    img.onload = () => {
      try {
        const canvasWidth = 800; // Set default width
        const canvasHeight = canvasWidth / 2; // Height to maintain 2:1 aspect ratio

        fabricCanvas.setWidth(canvasWidth);
        fabricCanvas.setHeight(canvasHeight);

        const scale = Math.min(
          canvasWidth / img.width,
          canvasHeight / img.height
        );

        fabricCanvas.clear();

        const fabricImage = new fabric.Image(img, {
          left: 0,
          top: 0,
          originX: "left",
          originY: "top",
          scaleX: scale,
          scaleY: scale,
          selectable: false,
          evented: false,
        });

        fabricCanvas.setBackgroundImage(
          fabricImage,
          fabricCanvas.renderAll.bind(fabricCanvas)
        );

        if (mapData) {
          fabricCanvas.loadFromJSON(
            JSON.parse(mapData),
            fabricCanvas.renderAll.bind(fabricCanvas),
            () => {
              const circles = fabricCanvas.getObjects("group");
              const existingCircles = circles.map(
                (group) => group.item(1).text
              );
              const highestNumber = Math.max(0, ...existingCircles.map(Number));
              setVendorIndex(highestNumber + 1);

              // Populate availableCircles with missing numbers
              const allCircleNumbers = Array.from(
                { length: data.length },
                (_, i) => (i + 1).toString()
              );
              const missingNumbers = allCircleNumbers.filter(
                (num) => !existingCircles.includes(num)
              );
              console.log(missingNumbers);
              setAvailableCircles(missingNumbers);
            }
          );
        } else {
          setVendorIndex(1);
          setAvailableCircles(
            Array.from({ length: data.length }, (_, i) => (i + 1).toString())
          );
        }

        fabricCanvas.on("mouse:down", handleCanvasClick);
      } catch (error) {
        console.error("Error loading the image onto the fabric canvas:", error);
      }
    };

    img.onerror = (error) => {
      console.error("Failed to load image:", error);
    };
  };

  const initializeCanvas = (mapImage) => {
    const canvas = canvasRef.current;
    if (canvas && !fabricCanvasRef.current) {
      fabricCanvasRef.current = new fabric.Canvas(canvas);
      loadMapImage(mapImage);
    }
  };

  const disposeCanvas = () => {
    const fabricCanvas = fabricCanvasRef.current;
    if (fabricCanvas) {
      fabricCanvas.off("mouse:down", handleCanvasClick);
      fabricCanvas.dispose();
      fabricCanvasRef.current = null;
    }
  };

  const handleCanvasClick = (event) => {
    const { x, y } = event.pointer;
    const fabricCanvas = fabricCanvasRef.current;

    let shapeClicked = false;
    fabricCanvas.forEachObject((obj) => {
      if (obj.type === "group") {
        const { left, top } = obj;
        const circle = obj.item(0);
        const radius = circle.radius * circle.scaleX;

        const distance = Math.sqrt(
          Math.pow(x - left, 2) + Math.pow(y - top, 2)
        );

        if (distance <= radius) {
          fabricCanvas.remove(obj);
          setAvailableCircles((prev) => {
            const circleNumber = obj.item(1).text;
            return [...prev, circleNumber];
          });

          shapeClicked = true;
          return;
        }
      }
    });

    if (!shapeClicked) {
      addShapeToCanvas({ x, y });
    }
  };

  const addShapeToCanvas = ({ x, y }) => {
    const highestNumber = Math.max(
      0,
      ...getAllNumberInMap(fabricCanvasRef?.current).map(Number)
    );
    setVendorIndex(highestNumber + 1);
    // Populate availableCircles with missing numbers
    const allCircleNumbers = Array.from({ length: data.length }, (_, i) =>
      (i + 1).toString()
    );
    const missingNumbers = allCircleNumbers.filter(
      (num) => !getAllNumberInMap(fabricCanvasRef?.current).includes(num)
    );
    console.log(missingNumbers);
    setAvailableCircles(missingNumbers);
    const fabricCanvas = fabricCanvasRef.current;
    if (fabricCanvas && missingNumbers.length > 0) {
      const circleRadius = 10;
      const circleNumber = missingNumbers[0]; // Get the first available number

      const text = new fabric.Text(circleNumber, {
        fontSize: 20,
        fill: "white",
        originX: "center",
        originY: "center",
        evented: false,
      });

      const circle = new fabric.Circle({
        radius: circleRadius,
        fill: "rgba(0,0,0)",
        originX: "center",
        originY: "center",
        evented: false,
      });

      const group = new fabric.Group([circle, text], {
        left: x,
        top: y,
        originX: "center",
        originY: "center",
      });

      fabricCanvas.add(group);

      setAvailableCircles((prev) => prev.slice(1)); // Remove the used number from availableCircles
    }
  };

  const saveCanvasAsImage = async () => {
    const fabricCanvas = fabricCanvasRef.current;
    if (fabricCanvas) {
      const mapData = JSON.stringify(fabricCanvas.toJSON());
      const canvasImageURL = fabricCanvas.toDataURL({
        format: "png",
        quality: 1,
      });
      const imageBlob = await fetch(canvasImageURL).then((res) => res.blob());
      const formData = new FormData();
      formData.append("mapImage", imageBlob, "canvas_image.png");
      formData.append("mapData", mapData);
      formData.append("marketdateId", date._id);

      const response = await postMapImage(formData);
      if (!response.error) {
        navigate("/map");
      }
    }
  };
  const getAllNumberInMap = (data) => {
    const number = [];
    console.log(fabricCanvasRef?.current?._objects[0]?._objects[1]?.text);
    for (let index = 0; index < data?._objects.length; index++) {
      const element = data?._objects[index];
      number.push(element?._objects[1]?.text);
    }
    return number;
  };



  const handleVendorClick = (vendor) => {
    setSelectedVendor(vendor);
    setIsModalOpen(true); // Open modal
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedVendor(null); // Clear selected vendor
  };
  console.log(getAllNumberInMap(fabricCanvasRef?.current));

  return (
    <div>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Map</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Map</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="modal-contentimage">
          <h2>Upload Market Map</h2>
          <div
            className="canvas-container"
            style={{ width: "800px", height: "400px" }}
          >
            <canvas ref={canvasRef} />
          </div>
          <button
            type="button"
            onClick={saveCanvasAsImage}
            className="btn btn-primary mt-3"
          >
            Save Map
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {" "}
          <div className="card-body">
            {
              <div className="map-container">
                <div className="map-data-overlay mt-4">
                  {data.map((vendor, index) => (
                    <a
                      key={vendor?.vendor?._id}
                      href="#"
                      className="genric-btn primary-border circle mb-2 "
                      data-toggle="modal"
                      data-target="#profileDetailsModal"
                      onClick={(e) => {
                        e.preventDefault();
                        handleVendorClick(vendor?.vendor); // Show vendor details in modal
                      }}
                    >
                      <span className="badge badge-warning badge-pill mr-2">
                        {index + 1}
                      </span>
                      <span className="mr-2">
                        {vendor?.vendor?.businessName} {"  "}
                      </span>
                    </a>
                  ))}
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <VendorMapModal
        vendor={selectedVendor}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default MapCanvas;
