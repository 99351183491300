import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link } from "react-router-dom";
import moment from "moment"; // Import moment

const VendorPaymentList = () => {
  const {
    getAllInvoiceVendor,
    getAllVendor,
    searchgetInvoiceSearchHistoryApi,
    getVendorDateInvoicePaymentUrl,
  } = useApiAxios();
  const [payments, setPayments] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = async (page = 1, limit = 1000) => {
    try {
      const vendorResponse = await getAllVendor();
      setVendors(vendorResponse.data.data);

      const invoiceResponse = await getAllInvoiceVendor(page, limit);
      setPayments(invoiceResponse.data.data);
      setTotalPages(invoiceResponse.data.data.totalPages);

      setLoading(false);
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleSearch = async (event) => {
    try {
      const query = event.target.value;
      setSearchQuery(query);
      const response = await searchgetInvoiceSearchHistoryApi(query);
      setPayments(response.data.data);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const DateComponent = (data) => {
    console.log(data);
    const formatDate = (dateString) => {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    // Extracting and formatting dates from dateId objects
    const formattedDates = data
      .map((item) => formatDate(item.dateId.date)) // Map and format each date
      .join(", "); // Join dates with commas
    return formattedDates;
  };
  const makePaymentHandler = async (id) => {
    try {
      const { data, error } = await getVendorDateInvoicePaymentUrl({
        vendorInvoiceId: id,
      });

      if (error || !data || !data.data.sessionUrl) {
        setErrorMessage("Failed to initiate payment. Please try again.");
        return;
      }

      window.location.href = data.data.sessionUrl;
    } catch (err) {
      setErrorMessage("An unexpected error occurred. Please try again.");
      console.error("Error during payment initiation:", err);
    }
  };
  return (
    <div>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Vendor Invoice Payment List</h2>
            </div>
          </div>
          {/* <div className="ml-md-auto py-2 py-md-0">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div class="collapse" id="search-nav">
                    <form class="navbar-left navbar-form nav-search mr-md-3">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <button
                            type="submit"
                            class="btn btn-search pr-1"
                            onClick={handleSearch}
                          >
                            <i class="fa fa-search search-icon"></i>
                          </button>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search ..."
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {errorMessage && (
        <div
          className="mt-3 alert alert-danger"
          style={{ padding: "15px 30px" }}
        >
          {errorMessage}
        </div>
      )}
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Sub Admin Name</th>
                      <th>Sub Admin Email</th>
                      <th>Vendor Name</th>
                      <th>Price</th>
                      <th>Date</th>
                      <th>Payment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.length > 0 ? (
                      payments.map((payment) => {
                        return (
                          <tr key={payment._id}>
                            <td>{payment?.subAdmin?.name}</td>
                            <td>{payment?.subAdmin?.email}</td>
                            <td>{payment?.vendorId?.businessName}</td>

                            <td>${payment?.price}</td>

                            <td>{DateComponent(payment?.vendorDates)}</td>
                            <td>
                              {payment.status === "InvoiceSend" ? (
                                <button
                                  onClick={() =>
                                    makePaymentHandler(payment._id)
                                  }
                                  className="btn btn-primary"
                                >
                                  Pay
                                </button>
                              ) : (
                                payment.status
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">No payments found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* <div className="pagination">
                  <button
                    className="btn btn-primary"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span>{`Page ${currentPage} of ${totalPages}`}</span>
                  <button
                    className="btn btn-primary"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorPaymentList;
