import React from "react";
import "./VendorChangeDetails.css"; // Import the CSS file
import { useLocation, useNavigate } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";

const VendorChangeDetails = () => {
  const location = useLocation();

  const { vendor } = location.state || {};
  console.warn("v;aue from the prop", vendor);
  const navigate = useNavigate();

  const { updateApprovalChanges, updateDisApprovalChanges } = useApiAxios();
  const imgUrl = process.env.REACT_APP_IMG_URL;

  const handleApprove = async (vendorId) => {
    try {
      await updateApprovalChanges(vendorId);
      navigate("/"); // Navigate to the home page after approval
    } catch (error) {
      console.error("Error approving vendor:", error);
    }
  };

  const handleDisApprove = async (vendorId) => {
    try {
      await updateDisApprovalChanges(vendorId);
      navigate("/"); // Navigate to the home page after approval
    } catch (error) {
      console.error("Error approving vendor:", error);
    }
  };

  return (
    <div>
      <h2>Vendor Changes</h2>

      <button
        className="btn btn-success m-2"
        onClick={() => handleApprove(vendor._id)}
      >
        Approve
      </button>
      <button
        className="btn btn-danger m-2"
        onClick={() => handleDisApprove(vendor._id)}
      >
        Disapprove
      </button>

      <div className="vendor-details">
        <div className="vendor-section">
          <h3>Current Vendor Information</h3>
          <table>
            <tbody>
              <tr>
                <th>Business Name</th>
                <td>{vendor.businessName}</td>
              </tr>

              <tr>
                <th>Category </th>
                <td>{vendor.subCategory[0]?.name}</td>
              </tr>
              <tr>
                <th>Sub Category</th>
                <td>{vendor.category[0]?.name}</td>
              </tr>

              <tr>
                <th>Contact Person</th>
                <td>{vendor.contactPerson}</td>
              </tr>
              <tr>
                <th>Contact Number</th>
                <td>{vendor.contactNumber}</td>
              </tr>
              <tr>
                <th>Business Description</th>
                <td>{vendor.businessDescription}</td>
              </tr>
              <tr>
                <th>Add Product Message</th>
                <td>{vendor.addProductMessage}</td>
              </tr>
              <tr>
                <th>Approval Status</th>
                <td>{vendor.approvalStatus}</td>
              </tr>
              <tr>
                <th>Role</th>
                <td>{vendor.role}</td>
              </tr>
              <tr>
                <th>Waitlist Status</th>
                <td>{vendor.waitList ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>Instagram</th>
                <td>
                  <a
                    href={vendor.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {vendor.instagram}
                  </a>
                </td>
              </tr>
              <tr>
                <th>Facebook</th>
                <td>
                  <a
                    href={vendor.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {vendor.facebook}
                  </a>
                </td>
              </tr>
              <tr>
                <th>Twitter</th>
                <td>{vendor.twitter || "Not Available"}</td>
              </tr>
              <tr>
                <th>TikTok</th>
                <td>{vendor.tikTok || "Not Available"}</td>
              </tr>
              <tr>
                <th>Created At</th>
                <td>{new Date(vendor.createdAt).toLocaleString()}</td>
              </tr>
              <tr>
                <th>Updated At</th>
                <td>{new Date(vendor.updatedAt).toLocaleString()}</td>
              </tr>
              <tr>
                <th>Profile Change Pending</th>
                <td>{vendor.profileChangePending ? "Yes" : "No"}</td>
              </tr>
            </tbody>
          </table>

          <h4>Product Images</h4>
          <div className="image-gallery">
            {vendor.productImage1 === "removed" ? (
              <div className="image-item">
                <h4>Product 1</h4>
                <p>This image has been removed.</p>
              </div>
            ) : (
              vendor.productImage1 && (
                <div className="image-item">
                  <h4>Product 1</h4>
                  <img
                    src={`${imgUrl}/${vendor.productImage1}`}
                    alt="Product 1"
                  />
                </div>
              )
            )}

            {vendor.productImage2 === "removed" ? (
              <div className="image-item">
                <h4>Product 2</h4>
                <p>This image has been removed.</p>
              </div>
            ) : (
              vendor.productImage2 && (
                <div className="image-item">
                  <h4>Product 2</h4>
                  <img
                    src={`${imgUrl}/${vendor.productImage2}`}
                    alt="Product 2"
                  />
                </div>
              )
            )}

            {vendor.productImage3 === "removed" ? (
              <div className="image-item">
                <h4>Product 3</h4>
                <p>This image has been removed.</p>
              </div>
            ) : (
              vendor.productImage3 && (
                <div className="image-item">
                  <h4>Product 3</h4>
                  <img
                    src={`${imgUrl}/${vendor.productImage3}`}
                    alt="Product 3"
                  />
                </div>
              )
            )}
          </div>

          <h4>License Images</h4>
          <div className="license-images">
            {vendor.publicLiabilityInsurance === "removed" ? (
              <div className="image-item">
                <strong>Public Liability Insurance:</strong>
                <p>This image has been removed.</p>
              </div>
            ) : vendor.publicLiabilityInsurance ? (
              <div className="image-item">
                <strong>Public Liability Insurance:</strong>
                <img
                  src={`${imgUrl}/${vendor.publicLiabilityInsurance}`}
                  alt="Public Liability Insurance"
                />
              </div>
            ) : (
              <p>Public Liability Insurance: Not Available</p>
            )}

            {vendor.foodRegistrationImage === "removed" ? (
              <div className="image-item">
                <strong>Food Registration Image:</strong>
                <p>This image has been removed.</p>
              </div>
            ) : vendor.foodRegistrationImage ? (
              <div className="image-item">
                <strong>Food Registration Image:</strong>
                <img
                  src={`${imgUrl}/${vendor.foodRegistrationImage}`}
                  alt="Food Registration"
                />
              </div>
            ) : (
              <p>Food Registration Image: Not Available</p>
            )}

            {vendor.liquorLicenseImage === "removed" ? (
              <div className="image-item">
                <strong>Liquor License Image:</strong>
                <p>This image has been removed.</p>
              </div>
            ) : vendor.liquorLicenseImage ? (
              <div className="image-item">
                <strong>Liquor License Image:</strong>
                <img
                  src={`${imgUrl}/${vendor.liquorLicenseImage}`}
                  alt="Liquor License"
                />
              </div>
            ) : (
              <p>Liquor License Image: Not Available</p>
            )}

            {vendor.otherLicenseImage === "removed" ? (
              <div className="image-item">
                <strong>Other License Image:</strong>
                <p>This image has been removed.</p>
              </div>
            ) : vendor.otherLicenseImage ? (
              <div className="image-item">
                <strong>Other License Image:</strong>
                <img
                  src={`${imgUrl}/${vendor.otherLicenseImage}`}
                  alt="Other License"
                />
              </div>
            ) : (
              <p>Other License Image: Not Available</p>
            )}
          </div>

          <h4>License Expiry Dates</h4>
          <ul>
            <li>
              Other License Expiry Date:{" "}
              {new Date(vendor.otherLicenseExpiryDate).toLocaleDateString()}
            </li>
            <li>
              Expire License Date:{" "}
              {new Date(vendor.expireLicenseDate).toLocaleDateString()}
            </li>
            <li>
              Insurance Expiry Date:{" "}
              {new Date(vendor.insuranceExpiryDate).toLocaleDateString()}
            </li>
            <li>
              Liquor License Expiry Date:{" "}
              {new Date(vendor.liquorLicenseExpiryDate).toLocaleDateString()}
            </li>
          </ul>
        </div>

        {/* Updated Vendor Details */}
        <div className="vendor-section">
          <h3>Updated Vendor Information</h3>
          <table>
            <tbody>
              {vendor.updateVendor.businessName && (
                <tr>
                  <th>Business Name</th>
                  <td>{vendor.updateVendor.businessName}</td>
                </tr>
              )}

              {vendor.updateVendor.subCategory?.length > 0 && (
                <tr>
                  <th>Category</th>
                  <td>{vendor.updateVendor.subCategory[0]?.name}</td>
                </tr>
              )}
              {vendor.updateVendor.category?.length > 0 && (
                <tr>
                  <th>Sub Category</th>
                  <td>{vendor.updateVendor.category[0]?.name}</td>
                </tr>
              )}

              {vendor.updateVendor.contactNumber && (
                <tr>
                  <th>Contact Number</th>
                  <td>{vendor.updateVendor.contactNumber}</td>
                </tr>
              )}
              {vendor.updateVendor.businessDescription && (
                <tr>
                  <th>Business Description</th>
                  <td>{vendor.updateVendor?.businessDescription}</td>
                </tr>
              )}
              {vendor.updateVendor.addProductMessage && (
                <tr>
                  <th>Add Product Message</th>
                  <td>{vendor.updateVendor.addProductMessage}</td>
                </tr>
              )}
              {vendor.updateVendor.instagram && (
                <tr>
                  <th>Instagram</th>
                  <td>
                    <a
                      href={vendor.updateVendor.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {vendor.updateVendor.instagram}
                    </a>
                  </td>
                </tr>
              )}
              {vendor.updateVendor.facebook && (
                <tr>
                  <th>Facebook</th>
                  <td>
                    <a
                      href={vendor.updateVendor.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {vendor.updateVendor.facebook}
                    </a>
                  </td>
                </tr>
              )}
              {vendor.updateVendor.twitter && (
                <tr>
                  <th>Twitter</th>
                  <td>
                    <a
                      href={vendor.updateVendor.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {vendor.updateVendor.twitter}
                    </a>
                  </td>
                </tr>
              )}
              {vendor.updateVendor.tikTok && (
                <tr>
                  <th>TikTok</th>
                  <td>
                    <a
                      href={vendor.updateVendor.tikTok}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {vendor.updateVendor.tikTok}
                    </a>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <h4>Product Images</h4>
          <div className="image-gallery">
            {vendor.updateVendor.productImage1 === "removed" ? (
              <p>Product Image 1 has been removed.</p>
            ) : (
              vendor.updateVendor.productImage1 && (
                <img
                  src={`${imgUrl}/${vendor.updateVendor.productImage1}`}
                  alt="Updated Product 1"
                />
              )
            )}

            {vendor.updateVendor.productImage2 === "removed" ? (
              <p>Product Image 2 has been removed.</p>
            ) : (
              vendor.updateVendor.productImage2 && (
                <img
                  src={`${imgUrl}/${vendor.updateVendor.productImage2}`}
                  alt="Updated Product 2"
                />
              )
            )}

            {vendor.updateVendor.productImage3 === "removed" ? (
              <p>Product Image 3 has been removed.</p>
            ) : (
              vendor.updateVendor.productImage3 && (
                <img
                  src={`${imgUrl}/${vendor.updateVendor.productImage3}`}
                  alt="Updated Product 3"
                />
              )
            )}
          </div>

          {vendor.updateVendor.expireLicenseDate && (
            <div>
              Expire License Date:{" "}
              {new Date(
                vendor.updateVendor.expireLicenseDate
              ).toLocaleDateString()}
            </div>
          )}

          {vendor.updateVendor.foodRegistrationExpiryDate && (
            <div>
              Food Registration Expiry Date:{" "}
              {new Date(
                vendor.updateVendor.foodRegistrationExpiryDate
              ).toLocaleDateString()}
            </div>
          )}

          {vendor.updateVendor.liquorLicenseExpiryDate && (
            <div>
              Liquor License Expiry Date:{" "}
              {new Date(
                vendor.updateVendor.liquorLicenseExpiryDate
              ).toLocaleDateString()}
            </div>
          )}

          {vendor.updateVendor.publicLiabilityInsuranceExpiryDate && (
            <div>
              Public Liability Insurance Expiry Date:{" "}
              {new Date(
                vendor.updateVendor.publicLiabilityInsuranceExpiryDate
              ).toLocaleDateString()}
            </div>
          )}

          <ul>
            {vendor.updateVendor.otherLicenseImage === "removed" ? (
              <li>Other License Image has been removed.</li>
            ) : (
              vendor.updateVendor.otherLicenseImage && (
                <li>
                  Other License Image:{" "}
                  <img
                    style={{
                      maxWidth: "100%", // Responsive image
                      height: "300px", // Maintain aspect ratio
                      border: "2px solid #ccc", // Optional border
                      borderRadius: "5px", // Optional rounded corners
                      margin: "5px 0", // Space between images
                    }}
                    src={`${imgUrl}/${vendor.updateVendor.otherLicenseImage}`}
                    alt="Other License"
                  />
                </li>
              )
            )}

            {vendor.updateVendor.foodRegistrationImage === "removed" ? (
              <li>Food Registration Image has been removed.</li>
            ) : (
              vendor.updateVendor.foodRegistrationImage && (
                <li>
                  Food Registration Image:{" "}
                  <img
                    style={{
                      maxWidth: "100%", // Responsive image
                      height: "300px", // Maintain aspect ratio
                      border: "2px solid #ccc", // Optional border
                      borderRadius: "5px", // Optional rounded corners
                      margin: "5px 0", // Space between images
                    }}
                    src={`${imgUrl}/${vendor.updateVendor.foodRegistrationImage}`}
                    alt="Food Registration"
                  />
                </li>
              )
            )}

            {vendor.updateVendor.liquorLicenseImage === "removed" ? (
              <li>Liquor License Image has been removed.</li>
            ) : (
              vendor.updateVendor.liquorLicenseImage && (
                <li>
                  Liquor License Image:{" "}
                  <img
                    style={{
                      maxWidth: "100%", // Responsive image
                      height: "300px", // Maintain aspect ratio
                      border: "2px solid #ccc", // Optional border
                      borderRadius: "5px", // Optional rounded corners
                      margin: "5px 0", // Space between images
                    }}
                    src={`${imgUrl}/${vendor.updateVendor.liquorLicenseImage}`}
                    alt="Liquor License"
                  />
                </li>
              )
            )}

            {vendor.updateVendor.publicLiabilityInsurance === "removed" ? (
              <li>Public Liability Insurance Image has been removed.</li>
            ) : (
              vendor.updateVendor.publicLiabilityInsurance && (
                <li>
                  Public Liability Insurance Image:{" "}
                  <img
                    style={{
                      maxWidth: "100%", // Responsive image
                      height: "300px", // Maintain aspect ratio
                      border: "2px solid #ccc", // Optional border
                      borderRadius: "5px", // Optional rounded corners
                      margin: "5px 0", // Space between images
                    }}
                    src={`${imgUrl}/${vendor.updateVendor.publicLiabilityInsurance}`}
                    alt="Public Liability Insurance"
                  />
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VendorChangeDetails;
