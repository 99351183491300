import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link } from "react-router-dom";

const PaymentHistory = () => {
  const { getAllMarket, paymentHistory } = useApiAxios();
  const [adminData, setAdminData] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [dates, setDates] = useState([]);
  const [selectedDateId, setSelectedDateId] = useState("");
  const [payments, setPayments] = useState({});


  const fetchData = async () => {
    const res = await getAllMarket();
    if (!res.error) {
      setAdminData(res.data.data);
      setMarkets(res.data.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedMarket) {
      const market = markets.find((market) => market.name === selectedMarket);
      if (market && market.marketDate) {
        setDates(
          market.marketDate.map((dateObj) => ({
            date: dateObj.date,
            id: dateObj._id,
          }))
        );
      } else {
        setDates([]);
      }
    } else {
      setDates([]);
    }
  }, [selectedMarket, markets]);

  const handleMarketChange = (e) => {
    setSelectedMarket(e.target.value);
    setSelectedDateId("");
    setPayments({});
  };

  const handleDateChange = async (e) => {
    const selectedId = e.target.value;
    setSelectedDateId(selectedId);


    if (selectedId) {
      const paymentData = await paymentHistory({ marketDateId: selectedId });

      if (paymentData) {
        setPayments(paymentData.data[0]);
      } else {
        setPayments({});
      }
    } else {
      setPayments({});
    }
  };

 


  return (
    <div>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Payment</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Payment History</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Market</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="marketSelect">Select Market</label>
                  <select
                    className="form-control"
                    id="marketSelect"
                    onChange={handleMarketChange}
                  >
                    <option value="">Select a market</option>
                    {markets.map((market, index) => (
                      <option key={index} value={market.name}>
                        {market.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="dateSelect">Select Date</label>
                  <select
                    className="form-control"
                    id="dateSelect"
                    onChange={handleDateChange}
                    value={selectedDateId}
                  >
                    <option value="">Select a date</option>
                    {dates.map((dateObj, index) => (
                      <option key={index} value={dateObj.id}>
                        {new Date(dateObj.date).toLocaleDateString()}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {payments.subscription && (
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Payment History</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Bussiness Name</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Payment For</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments.subscription.map((subscription, index) => (
                        <tr key={index}>
                          <td>{subscription.vendor.vendor.businessName}</td>
                          <td>{subscription.amount}</td>
                          <td>{subscription.status}</td>
                          <td>{subscription.paymentFor}</td>
                          <td>
                            {new Date(subscription.date).toLocaleDateString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
