import React, { useState, useEffect, useRef } from "react";

import { useFormik } from "formik";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import useApiAxios from "../../api/useApiAxios";
import { useNavigate, useParams, Link } from "react-router-dom";
import EditSectionModal from "../Modal/editSectionModal";
import AddSectionModal from "../Modal/addSectionModal";
import { HexColorPicker } from "react-colorful"; // Import HexColorPicker
import * as Yup from "yup";
import DragsAndDrop from "../../image/DragsAndDrop";

const EditSubAdminLandingPageDetails = () => {
  const MAX_CHAR_COUNT = 256;
  const { marketId } = useParams();
  const {
    getMarketById,
    updateMarketBannerImageApi,
    deleteMarketSectionApi,
    updateSectionApi,
    updateSectionImageApi,
    updateSubAdminLandingPageContent,
  } = useApiAxios();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [marketData, setMarketData] = useState();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [headerColor, setHeaderColor] = useState("#ffffff"); // Initialize header color state
  const [footerColor, setFooterColor] = useState("#ffffff"); // Initialize footer color state
  const navigate = useNavigate();
  const imageUrl = process.env.REACT_APP_IMG_URL;
  const [sections, setSections] = useState([]);
  const [showAddSectionModal, setShowAddSectionModal] = useState(false);
  const [editThumbnailUrl, setEditThumbnailUrl] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(""); // State for file size error

  const fetchMarketData = async (marketId) => {
    try {
      const response = await getMarketById(marketId);
      setMarketData(response.data.data.marketContent);
    

      // Set the colors from the fetched data
      setHeaderColor(response.data.data.marketContent.headerColor || "#ffffff");
      setFooterColor(response.data.data.marketContent.footerColor || "#ffffff");
    } catch (error) {
      console.error("Error fetching market data:", error);
    }
  };

  useEffect(() => {
    fetchMarketData(marketId);
  }, [marketId]);

  useEffect(() => {
    if (marketData) {
      formik.setValues({
        mainTitle: marketData.mainTitle || "",
        title: marketData.title || "",
        description: marketData.description || "",
        bannerImage: marketData.bannerImage || "",
        footerDescription: marketData.footerDescription || "",
        facebookUrl: marketData.facebookUrl || "",
        twitterUrl: marketData.twitterUrl || "",
        tiktokUrl: marketData?.tiktokUrl || "",
        instaUrl: marketData?.instaUrl || "",
        termsAndConditions: marketData.termsAndConditions || "",
      });
    }
  }, [marketData]);

  const validationSchema = Yup.object({
    mainTitle: Yup.string()
      .required("Main Title is required")
      .max(100, "Main Title must be at most 100 characters"),
    title: Yup.string()
      .required("Title is required")
      .max(100, "Title must be at most 100 characters"),
    // description: Yup.string()
    //   .required("Description is required")
    //   .min(5, "Description must be at least 5 characters")
    //   .max(256, "Description must be at most 256 characters"),

    footerDescription: Yup.string().required("Footer Description is required"),
    termsAndConditions: Yup.string().required(
      "Terms and Conditions are required"
    ),
    headerColor: Yup.string().matches(
      /^#([0-9A-F]{3}){1,2}$/i,
      "Invalid header color format"
    ),
    footerColor: Yup.string().matches(
      /^#([0-9A-F]{3}){1,2}$/i,
      "Invalid footer color format"
    ),
  });

  const mainTitleRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const bannerImageRef = useRef(null);
  const footerDescriptionRef = useRef(null);
  const termsAndConditionsRef = useRef(null);
  const headerColorRef = useRef(null);
  const footerColorRef = useRef(null);

  const initialValues = {
    mainTitle: marketData?.mainTitle || "",
    title: marketData?.title || "",
    description: marketData?.description || "",
    bannerImage: marketData?.bannerImage || "",
    footerDescription: marketData?.footerDescription || "",
    facebookUrl: marketData?.facebookUrl || "",
    twitterUrl: marketData?.twitterUrl || "",
    tiktokUrl: marketData?.tiktokUrl || "",
    instaUrl: marketData?.instaUrl || "",

    termsAndConditions: marketData?.termsAndConditions || "",
    headerColor: marketData?.headerColor || "",
    footerColor: marketData?.footerColor || "",
  };

  const addSection = (sectionData) => {
    setSections([...sections, sectionData]);
  };
  const handleUpdateBannerImage = async (contentId, bannerImage) => {
    // Check if the file exists and its size is below 4 MB
    if (bannerImage && bannerImage.size > 4 * 1024 * 1024) {
      formik.setFieldError("bannerImage", "File size must be less than 4 MB..");
      return;
    }

    if (
      bannerImage &&
      !["image/jpg", "image/jpeg", "image/png"].includes(bannerImage.type)
    ) {
      formik.setFieldError("bannerImage", "Unsupported file format.");
      return;
    }

    try {
      const imageData = new FormData();
      imageData.append("bannerImage", bannerImage);

      const result = await updateMarketBannerImageApi(contentId, imageData);
      if (!result.error) {
        formik.setFieldError("bannerImage", "");
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error("Error updating banner image:", error);
    }
  };

  const removeSection = async (id, index) => {
    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
    formik.setFieldValue("section", updatedSections);
    try {
      const response = await deleteMarketSectionApi(id);
      if (response.success) {
        console.log("Section deleted successfully!");
       
      } else {
        console.error("Failed to delete section:", response.error);
      }
    } catch (error) {
      console.error("Error deleting section:", error);
    }
    fetchMarketData(marketId);
  };

  const handleSectionChange = (index, updatedSection) => {
    const updatedSections = [...sections];
    updatedSections[index] = updatedSection;
    setSections(updatedSections);
  };

  const handleUploadImage = async (imageFile) => {
    try {
      const imageData = new FormData();
      imageData.append("image", imageFile);
      const result = await updateSectionImageApi(imageData);
      if (!result.error) {
        setUploadedImage(result.data.data.image);
        return result.data.data.image;
      } else {
        console.error(result.message);
        return null;
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  const handleEditClick = (section) => {
    setSelectedSection(section);
    setShowEditModal(true);
  };

  const handleEditSection = async (sectionId, newSectionData, callback) => {
    try {
      const updateSectionResponse = await updateSectionApi(
        sectionId,
        newSectionData
      );
      if (updateSectionResponse.success) {
        const updatedSections = sections.map((section) =>
          section._id === sectionId
            ? { ...section, ...newSectionData }
            : section
        );
        setSections(updatedSections);
        setShowEditModal(false);
        if (typeof callback === "function") {
          callback();
        }
      } else {
        console.error(
          "Failed to update section data:",
          updateSectionResponse.error
        );
        await fetchMarketData(marketId);
      }
    } catch (error) {
      console.error("Error updating section:", error);
    }
  };

  const onSubmit = async (values) => {
    const updatedContent = {
      mainTitle: values.mainTitle,
      title: values.title,
      description: values.description,
      bannerImage: values.bannerImage,
      footerDescription: values.footerDescription,
      facebookUrl: values.facebookUrl,
      tiktokUrl: values.tiktokUrl,
      instaUrl: values.instaUrl,

      twitterUrl: values.twitterUrl,
      termsAndConditions: values.termsAndConditions,
      headerColor, // Include header color
      footerColor, // Include footer color
      sections: sections.map((section) => ({
        title: section.title,
        description: section.description,
        image: section.image,
      })),
    };
    await updateSubAdminLandingPageContent(marketData._id, updatedContent);
    navigate("/marketdate");
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleCancel = () => {
    navigate("/marketdate");
  };

  const handleModalClose = () => {
    fetchMarketData(marketId);
  };
  console.log(formik.errors);

  useEffect(() => {
    if (formik.errors.mainTitle) {
      mainTitleRef.current?.focus();
    } else if (formik.errors.title) {
      titleRef.current?.focus();
    } else if (formik.errors.description) {
      descriptionRef.current?.focus();
    } else if (formik.errors.bannerImage) {
      bannerImageRef.current?.focus();
    } else if (formik.errors.footerDescription) {
      footerDescriptionRef.current?.focus();
    } else if (formik.errors.termsAndConditions) {
      termsAndConditionsRef.current?.focus();
    } else if (formik.errors.headerColor) {
      headerColorRef.current?.focus();
    } else if (formik.errors.footerColor) {
      footerColorRef.current?.focus();
    }
  }, [formik.errors, formik.touched]);

  const handleColorChange = (colorSetter) => (color) => {
    colorSetter(color);
    formik.setFieldValue(
      colorSetter === setHeaderColor ? "headerColor" : "footerColor",
      color
    );
  };

  // const handleDescriptionChange = (content) => {
  //   // Count words
  //   const words = content.trim().split(/\s+/);
  //   const wordCount = words.length;

  //   if (wordCount > MAX_CHAR_COUNT) {
  //     // Truncate content to the first MAX_WORD_COUNT words
  //     const truncatedContent = words.slice(0, MAX_CHAR_COUNT).join(" ");
  //     formik.setFieldValue("description", truncatedContent);

  //     // Optionally, you can also alert the user
  //     alert(`You can only enter up to ${MAX_CHAR_COUNT} words.`);
  //   } else {
  //     // Update the formik value if within the limit
  //     formik.setFieldValue("description", content);
  //   }
  // };





  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };


  const handleDescriptionChange = (content) => {
    // Convert HTML content to plain text
    const textContent = stripHtmlTags(content);
    
    // Count words
    const words = textContent.trim().split(/\s+/);
    const wordCount = words.length;
  
    if (wordCount > MAX_CHAR_COUNT) {
      // Create a temporary div to work with HTML content
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;
      
      // Truncate the content based on the word limit
      let truncatedContent = '';
      let currentWordCount = 0;
  
      const traverseNodes = (node) => {
        if (currentWordCount >= MAX_CHAR_COUNT) return;
  
        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent;
          const textWords = text.split(/\s+/);
          const remainingWords = MAX_CHAR_COUNT - currentWordCount;
  
          if (textWords.length > remainingWords) {
            truncatedContent += textWords.slice(0, remainingWords).join(' ');
            currentWordCount += remainingWords;
          } else {
            truncatedContent += text;
            currentWordCount += textWords.length;
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const newElement = document.createElement(node.tagName);
          for (const attr of node.attributes) {
            newElement.setAttribute(attr.name, attr.value);
          }
          node.childNodes.forEach((child) => traverseNodes(child));
          if (newElement.innerHTML) {
            truncatedContent += newElement.outerHTML;
          }
        }
      };
  
      traverseNodes(tempDiv);
      formik.setFieldValue('description', truncatedContent);
  
      // Optionally, alert the user
      alert(`You can only enter up to ${MAX_CHAR_COUNT} words.`);
    } else {
      // Update the formik value if within the limit
      formik.setFieldValue('description', content);
    }
  };

  

  const handleBannerImageChange = async (file) => {
    if (file) {
      // Validate file size
      if (file.size > 4 * 1024 * 1024) {
        formik.setFieldError(
          "bannerImage",
          "File size must be less than 4 MB."
        );
        return;
      }

      // Validate file format
      if (!["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
        formik.setFieldError("bannerImage", "Unsupported file format.");
        return;
      }

      // Clear previous error if validation passes
      formik.setFieldError("bannerImage", "");

      // Upload the image and update the preview
      await handleUpdateBannerImage(marketData._id, file);

      // Create a preview URL for the uploaded image
      const reader = new FileReader();
      reader.onload = () => {
        setEditThumbnailUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="panel-header">
        <div className="page-inner py- mb-4">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Edit Detail of LandingPage</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Edit LandingPage</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="card-title">Edit LandingPage Details</div>
              </div>
              <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="col-md-12">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="mainTitle">Main Title</label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.mainTitle && formik.touched.mainTitle
                              ? "is-invalid"
                              : ""
                          }`}
                          id="mainTitle"
                          name="mainTitle"
                          placeholder="Main Title"
                          value={formik.values.mainTitle}
                          onChange={formik.handleChange}
                          maxLength={100}
                          ref={mainTitleRef}
                        />
                        {formik.errors.mainTitle &&
                          formik.touched.mainTitle && (
                            <div className="" style={{ color: "red" }}>
                              {formik.errors.mainTitle}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.title && formik.touched.title
                              ? "is-invalid"
                              : ""
                          }`}
                          id="title"
                          name="title"
                          placeholder="Title"
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          maxLength={100}
                          ref={titleRef}
                        />
                        {formik.errors.title && formik.touched.title && (
                          <div className="" style={{ color: "red" }}>
                            {formik.errors.title}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <SunEditor
                        setOptions={{
                          buttonList: [
                            ["undo", "redo"],
                            ["font", "fontSize", "formatBlock"],
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["fontColor", "hiliteColor"],
                            ["indent", "outdent"],
                            ["align", "horizontalRule", "list", "table"],
                            ["link", "image", "video"],
                            ["fullScreen", "showBlocks", "codeView"],
                          ],
                          charCounterLabel: "Character Count:",
                          maxCharCount: MAX_CHAR_COUNT, // Set max characters allowed
                        }}
                        height="100px"
                        accept=".jpg, .jpeg, .png" // Add this line
                        ref={descriptionRef}
                        onChange={handleDescriptionChange}
                        setContents={formik.values.description}
                      />
                      {formik.errors.description &&
                        formik.touched.description && (
                          <div className="" style={{ color: "red" }}>
                            {formik.errors.description}
                          </div>
                        )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="bannerImage">Select Banner Image</label>
                     
                      {formik.errors.bannerImage ? (
                        <div className="" style={{ color: "red" }}>
                          {formik.errors.bannerImage}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <DragsAndDrop
                      heading="Upload Image"
                      inputName="Image"
                      imgKey={formik.values.bannerImage}
                      aspect={2 / 1}
                      uploadFile={(x) => {
                        handleBannerImageChange(x);
                      }}
                    />

                    <div className="form-group">
                      <label htmlFor="footerDescription">
                        Footer Description
                      </label>
                      <SunEditor
                        setOptions={{
                          buttonList: [
                            ["undo", "redo"],
                            ["font", "fontSize", "formatBlock"],
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["fontColor", "hiliteColor"],
                            ["indent", "outdent"],
                            ["align", "horizontalRule", "list", "table"],
                            ["link", "image", "video"],
                            ["fullScreen", "showBlocks", "codeView"],
                          ],
                        }}
                        onChange={(content) =>
                          formik.setFieldValue("footerDescription", content)
                        }
                        setContents={formik.values.footerDescription}
                        height="250px"
                        ref={footerDescriptionRef}
                      />
                      {formik.errors.footerDescription &&
                        formik.touched.footerDescription && (
                          <div className="" style={{ color: "red" }}>
                            {formik.errors.footerDescription}
                          </div>
                        )}
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="facebookUrl">Facebook URL</label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.facebookUrl &&
                            formik.touched.facebookUrl
                              ? "is-invalid"
                              : ""
                          }`}
                          id="facebookUrl"
                          name="facebookUrl"
                          placeholder="Facebook URL"
                          value={formik.values.facebookUrl}
                          onChange={formik.handleChange}
                          maxLength={100}
                          ref={termsAndConditionsRef}
                        />
                        {formik.errors.facebookUrl &&
                          formik.touched.facebookUrl && (
                            <div className="">{formik.errors.facebookUrl}</div>
                          )}
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="twitterUrl">Twitter URL</label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.twitterUrl &&
                            formik.touched.twitterUrl
                              ? "is-invalid"
                              : ""
                          }`}
                          id="twitterUrl"
                          name="twitterUrl"
                          placeholder="Twitter URL"
                          value={formik.values.twitterUrl}
                          onChange={formik.handleChange}
                          maxLength={100}
                        />
                        {formik.errors.twitterUrl &&
                          formik.touched.twitterUrl && (
                            <div className="">{formik.errors.twitterUrl}</div>
                          )}
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="tiktokUrl">Tiktok URL</label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.tiktokUrl && formik.touched.tiktokUrl
                              ? "is-invalid"
                              : ""
                          }`}
                          id="tiktokUrl"
                          name="tiktokUrl"
                          placeholder="Tiktok URL"
                          value={formik.values.tiktokUrl}
                          onChange={formik.handleChange}
                          maxLength={100}
                        />
                        {formik.errors.tiktokUrl &&
                          formik.touched.tiktokUrl && (
                            <div className="">{formik.errors.tiktokUrl}</div>
                          )}
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="instaUrl">Instagram URL</label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.instaUrl && formik.touched.instaUrl
                              ? "is-invalid"
                              : ""
                          }`}
                          id="instaUrl"
                          name="instaUrl"
                          placeholder="Instagram URL"
                          value={formik.values.instaUrl}
                          onChange={formik.handleChange}
                          maxLength={100}
                        />
                        {formik.errors.instaUrl && formik.touched.instaUrl && (
                          <div className="">{formik.errors.instaUrl}</div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="termsAndConditions">
                        Terms and Conditions
                      </label>
                      <SunEditor
                        setOptions={{
                          buttonList: [
                            ["undo", "redo"],
                            ["font", "fontSize", "formatBlock"],
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["fontColor", "hiliteColor"],
                            ["indent", "outdent"],
                            ["align", "horizontalRule", "list", "table"],
                            ["link", "image", "video"],
                            ["fullScreen", "showBlocks", "codeView"],
                          ],
                        }}
                        height="250px"
                        onChange={(content) =>
                          formik.setFieldValue("termsAndConditions", content)
                        }
                        setContents={formik.values.termsAndConditions}
                      />
                      {formik.errors.termsAndConditions &&
                        formik.touched.termsAndConditions && (
                          <div className="" style={{ color: "red" }}>
                            {formik.errors.termsAndConditions}
                          </div>
                        )}
                    </div>

                    <div className="form-row">
                      {/* Color Pickers */}
                      <div className="form-group col-md-3">
                        <label>Header Color</label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <HexColorPicker
                            color={headerColor}
                            onChange={handleColorChange(setHeaderColor)}
                            style={{
                              marginBottom: "20px",
                              marginRight: "10px",
                            }}
                          />
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: headerColor,
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          />
                          <input
                            type="text"
                            value={headerColor}
                            onChange={(e) =>
                              handleColorChange(setHeaderColor)(e.target.value)
                            }
                            ref={headerColorRef}
                            maxLength={7}
                            className="form-control mt-2"
                            style={{
                              width: "100%",
                              padding: "5px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          />
                          {formik.errors.headerColor &&
                            formik.touched.headerColor && (
                              <div style={{ color: "red" }} className="">
                                {formik.errors.headerColor}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="form-group col-md-3">
                        <label>Footer Color</label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <HexColorPicker
                            color={footerColor}
                            onChange={handleColorChange(setFooterColor)}
                            style={{
                              marginBottom: "20px",
                              marginRight: "10px",
                            }}
                          />

                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: footerColor,
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          />
                          <input
                            type="text"
                            value={footerColor}
                            className="form-control mt-2"
                            onChange={(e) =>
                              handleColorChange(setFooterColor)(e.target.value)
                            }
                            ref={footerColorRef}
                            maxLength={7}
                            style={{
                              width: "100%",
                              padding: "5px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          />
                          {formik.errors.footerColor &&
                            formik.touched.footerColor && (
                              <div style={{ color: "red" }}>
                                {formik.errors.footerColor}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <h2>Section Data</h2>

                    <div>
                      <AddSectionModal
                        show={showAddSectionModal}
                        handleClose={() => {
                          setShowAddSectionModal(false);
                          handleModalClose();
                        }}
                        addSection={addSection}
                        marketContentId={marketData?._id}
                      />
                      <a
                        className="btn btn-success"
                        onClick={() => setShowAddSectionModal(true)}
                      >
                        Add Section
                      </a>
                    </div>
                  </div>

                  <div className="form-group">
                    <table
                      className="table"
                      style={{
                        marginTop: "20px",
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr style={{ borderBottom: "1px solid #ddd" }}>
                          <th>Title</th>
                          <th>Image</th>
                          <th>Description</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {marketData &&
                          marketData.section &&
                          marketData.section.map((section, index) => (
                            <tr
                              key={index}
                              style={{ borderBottom: "1px solid #ddd" }}
                            >
                              <td>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: section.title,
                                  }}
                                />
                              </td>
                              <td style={{ maxWidth: "200px" }}>
                                {section.image && (
                                  <img
                                    src={`${imageUrl}/${section.image}`}
                                    alt="Section Image"
                                    style={{
                                      width: "100%",
                                      maxHeight: "200px",
                                      objectFit: "cover",
                                      marginTop: "20px",
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: section.description,
                                  }}
                                />
                              </td>
                              <td>
                                <div className="btn-group" role="group">
                                  <a
                                    className="btn btn-danger"
                                    onClick={() =>
                                      removeSection(section._id, index)
                                    }
                                    style={{ marginRight: "5px" }}
                                  >
                                    Remove
                                  </a>
                                  <a
                                    className="btn btn-warning"
                                    onClick={() => handleEditClick(section)}
                                  >
                                    Edit
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  {selectedSection && (
                    <EditSectionModal
                      show={showEditModal}
                      handleClose={() => {
                        setShowEditModal(false);
                        handleModalClose();
                      }}
                      sectionData={selectedSection}
                      handleEditSection={handleEditSection}
                    />
                  )}

                  <div className="card-action">
                    <button className="btn btn-primary m-2" type="submit">
                      Save
                    </button>
                    <a className="btn btn-danger" onClick={handleCancel}>
                      Cancel
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSubAdminLandingPageDetails;
