import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Modal from "react-modal"; // Assuming you're using a modal library
import VendorChangeDetails from "./VendorChangeDetails"; // Import the new child component

const ApproveVendorChanges = (props) => {
  const {
    getAllVendorApprovalChanges,
    searchVendorApi,
    updateApprovalChanges,
    updateDisApprovalChanges,
    waitListVendorListStatus,
  } = useApiAxios();

  const [vendorData, setVendorData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [vendorsPerPage, setVendorsPerPage] = useState(10);
  const vendorsPerPageOptions = [1, 5, 10, 20, 50, 100];
  const [total, setTotal] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const navigate = useNavigate();

  const fetchVendors = async (page, limit) => {
    try {
      const response = await getAllVendorApprovalChanges(page, limit);
      setVendorData(response.data.data);
      setTotal(response.data.total);
    } catch (error) {
      setVendorData([]);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchVendors(currentPage + 1, vendorsPerPage);
  }, [currentPage, vendorsPerPage]);

  const handleApprove = async (vendorId) => {
    try {
      await updateApprovalChanges(vendorId);
      fetchVendors(currentPage + 1, vendorsPerPage);
    } catch (error) {
      console.error("Error approving vendor:", error);
    }
  };

  const handleDisApprove = async (vendorId) => {
    try {
      await updateDisApprovalChanges(vendorId);
      fetchVendors(currentPage + 1, vendorsPerPage);
    } catch (error) {
      console.error("Error approving vendor:", error);
    }
  };

  const handleSearch = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const response = await searchVendorApi(query);
      setVendorData(response.data.data);
    } else {
      fetchVendors(currentPage + 1, vendorsPerPage);
    }
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleVendorsPerPageChange = (event) => {
    setVendorsPerPage(Number(event.target.value));
    setCurrentPage(0);
  };

  const compareVendorData = (original, updated) => {
    const changes = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        changes[key] = { original: original[key], updated: updated[key] };
      }
    }
    return changes;
  };

  // const openModal = (vendor) => {
  //   console.log("Opening modal for vendor:", vendor);
  //   setSelectedVendor(vendor);
  //   setModalIsOpen(true);
  // };

  const indexOfLastVendor = (currentPage + 1) * vendorsPerPage;
  const indexOfFirstVendor = indexOfLastVendor - vendorsPerPage;
  const currentVendors = vendorData.slice(
    indexOfFirstVendor,
    indexOfLastVendor
  );

  const viewChanges = (vendor) => {
    navigate("/approvevendorchangesdetails", { state: { vendor } });
  };
  return (
    <>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <h2 className="pb-2 fw-bold">Approve Vendor Changes</h2>
            <div className="ml-md-auto py-2 py-md-0">
              <input
                type="text"
                placeholder="Search ..."
                value={searchQuery}
                onChange={handleSearch}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt--5">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Approve Vendor Changes</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="display table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Business Name</th>
                    <th>Contact Person</th>
                    <th>Contact Number</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentVendors.map((vendor) => (
                    <tr key={vendor._id}>
                      <td>{vendor.businessName || "Not Available"}</td>

                      <td>{vendor.contactPerson || "Not Available"}</td>
                      <td>{vendor.contactNumber || "Not Available"}</td>
                      <td>
                        <button
                          className="btn btn-info"
                          onClick={() => viewChanges(vendor)}
                        >
                          View Changes
                        </button>
                        <button
                          className="btn btn-success m-2"
                          onClick={() => handleApprove(vendor._id)}
                        >
                          Approve
                        </button>
                        <button
                          className="btn btn-danger m-2"
                          onClick={() => handleDisApprove(vendor._id)}
                        >
                          Disapprove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination-container">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  pageCount={Math.ceil(total / vendorsPerPage)}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                />
                <select
                  value={vendorsPerPage}
                  onChange={handleVendorsPerPageChange}
                  className="form-control m-2"
                >
                  {vendorsPerPageOptions.map((option) => (
                    <option key={option} value={option}>
                      Show {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default ApproveVendorChanges;
