import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const InvoiceHistory = () => {
  const { getAllInvoice } = useApiAxios();
  const [invoiceData, setInvoiceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [invoicePerPage, setInvoicePerPage] = useState(10);
  const invoicePerPageOptions = [ 10, 20, 50, "All"];
  const [totalPages, setTotalPages] = useState(0);

  const fetchData = async (page, limit) => {
    try {
      const response = await getAllInvoice(page, limit);
      if (!response.error) {
        const totalCount = response.data.total;
        console.log(response.data.data)
        setInvoiceData(response.data.data);

        if (limit === "All") {
          setTotalPages(1); 
        } else {
          setTotalPages(Math.ceil(totalCount / limit)); // Calculate total pages
        }
      } else {
        setInvoiceData([]);
        console.error("Error fetching data:", response.data);
      }
    } catch (error) {
      console.log(error)
      setInvoiceData([]);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage + 1, invoicePerPage === "All" ? 3000 : invoicePerPage);
  }, [currentPage, invoicePerPage]);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage); // Update the current page
  };

  const handleInvoicePerPageChange = (event) => {
    const value = event.target.value;
    setInvoicePerPage(value);
    setCurrentPage(0); // Reset to the first page when changing items per page
  };

  const DateComponent = (data) => {
    if (!data || !Array.isArray(data)) {
      return "No Dates Available"; 
    }

    const formatDate = (dateString) => {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const formattedDates = data
      .map((item) => item.dateId?.date ? formatDate(item.dateId.date) : null)
      .filter(Boolean) 
      .join(", ");

    return formattedDates || "No Dates Available";
  };

  // Calculate indices for pagination
  const indexOfLastInvoice = (currentPage + 1) * invoicePerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicePerPage;

  return (
    <>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Invoice List</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/vendorlist">Invoice List</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">Invoices</h4>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Business Name</th>
                        <th>Email</th>
                        <th>Price</th>
                        <th>Dates</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceData.length > 0 ? (
                        invoiceData.map((invoice) => (
                          <tr key={invoice._id}>
                            <td>{invoice.vendorId?.businessName || "Not Available"}</td>
                            <td>{invoice.vendorId?.email || "Not Available"}</td>
                            <td>{invoice.price || "Not Available"}</td>
                            <td>{DateComponent(invoice.vendorDates)}</td>
                            <td>{invoice.status || "Not Available"}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">No Invoices Available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between align-items-center pagination-container">
                    <div className="page-info">
                      Page {currentPage + 1} of {totalPages}
                    </div>
                    <div className="form-group d-flex align-items-center items-per-page">
                      <label htmlFor="invoicesPerPage">Items per page: </label>
                      <select
                        id="invoicesPerPage"
                        value={invoicePerPage}
                        onChange={handleInvoicePerPageChange}
                        className="form-control m-2"
                        style={{ width: "auto", display: "inline-block" }}
                      >
                        {invoicePerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option === "All" ? "Show All" : `Show ${option}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {invoicePerPage !== "All" && (
                    <div className="d-flex justify-content-center">
                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={totalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceHistory;
