import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import Button from "react-bootstrap/esm/Button";

const DragsAndDrop = (props) => {
  const imgUrl = process.env.REACT_APP_IMG_URL;
  function dataURLtoFile(dataUrl, filename) {
    var arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const [previewImg, setPreviewImg] = useState("");
  const [cropImg, setCropImg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.addEventListener("load", () => setCropImg(reader.result));
    handleOpen();
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },
  });

  const setImage = (data) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.addEventListener("load", () => setPreviewImg(reader.result));
    props.uploadFile(data);
  };

  return (
    <>
      <div {...getRootProps()} >
        <img
       
         style={{
          maxHeight: "350px",
          maxWidth: "100%",
          // height: "350px",
          // width: "350px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.8)", // Shadow effect
          borderRadius: "5px", // Optional: for rounded corners
        }}
        
        src={
          previewImg
            ? previewImg
            : props.imgKey && props.imgKey !== "removed" // Check if imgKey exists and is not "removed"
              ? props.imgKey.startsWith("http")
                ? props.imgKey
                : `${imgUrl}/${props.imgKey}`
              : "/assets/img/dummyImage.jpg" // Show the dummy image if imgKey is "removed"
        }
        
          alt="Default"
        />

        <input {...getInputProps()} />
      </div>
    
        <Modal
          show={openModal}
          onHide={handleClose}
        >
           <Modal.Header>
           <Modal.Title>{props?.heading}</Modal.Title>
           <button
            type="button"
            className="close"
            onClick={() => handleClose()}
          >
     
          </button>
           </Modal.Header>
           <Modal.Body>
          <div stayle={style}>
            <div style={{ position: "relative", width: "100%", height: 400 }}>
              <Cropper
                image={cropImg}
                crop={crop}
                zoom={zoom}
                aspect={props.aspect}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div> 

            <div>
             
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={async () => {
                  var file = dataURLtoFile(
                    await getCroppedImg(cropImg, croppedArea),
                    "hello.jpeg"
                  );
                  setImage(file);
                  handleClose();
                }}
                className="mt-3"
              >
                Crop
              </Button>
            </div>
          </div>
            </Modal.Body>
        </Modal> 
     
    </>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  divShadow: 24,
  p: 4,
};

export default DragsAndDrop;
