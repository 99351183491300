import { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import PasswordResetForm from "./PasswordResetForm";
const ForgetPassword = () => {
  const { changePasswordOTP, forgetPasswordApi } = useApiAxios();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [otpSent, setOtpSent] = useState(false); // State to track OTP sent status
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await forgetPasswordApi({ email: values.email });
        if (response.error) {
          setMessage(response?.data?.message || "Invalid email address");
        } else {
          setEmail(values.email);
          setMessage(`OTP Sent Successfully to ${values.email}!`);
          setOtpSent(true);
        }
      } catch (error) {
        setMessage(error.response?.data?.message || "Invalid email address");
      }
    },
  });

  return (
    <div className="d-lg-flex half">
      <div
        className="bg order-md-2 d-none d-lg-block"
        style={{
          backgroundImage: 'url("assets/images/login.png")',
          backgroundSize: "covers",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          backgroundPosition: "bottom",
        }}
      />
      <div className="contents">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7">
              <Link to="/">
                <h3>Back to Login</h3>
              </Link>

              <h3 className="fw-bold login-title">Forgot Password</h3>
              {message && (
                <div
                  className={`alert ${
                    message.includes("Successfully")
                      ? "alert-success"
                      : "alert-danger"
                  }`}
                >
                  {message}
                </div>
              )}
              <form onSubmit={formik.handleSubmit}>
                <div
                  className="form-group  my-2 "
                  style={{ borderBottom: "1px solid #efefef" }}
                >
                  <label htmlFor="email" className="form-label ">
                    Email
                  </label>
                  <input
                    type="email"
                    className={`form-control ${
                      formik.touched.email && formik.errors.email
                        ? "is-invalid"
                        : ""
                    }`}
                    id="email"
                    disabled={otpSent}
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
                <button
                  type="submit"
                  disabled={otpSent}
                  className="btn btn-primary w-100 mb-4 "
                >
                  Send OTP
                </button>
              </form>
              {otpSent && (
                <PasswordResetForm
                  email={email}
                  changePasswordOTP={changePasswordOTP}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
